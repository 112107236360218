import React from 'react';
import { slide as Menu } from 'react-burger-menu';

export default class HomePage extends React.Component
{

    constructor( props )
    {
        super( props );
        this.state = { showVideo: false };
    }
    componentDidMount()
    {

    }

    render () {


        return (

         <div>
            <div className="home_page_background"></div>
            {this.state.showVideo && <video id="video" className="home_page_video" controls autoPlay muted src="https://fst.fabtale.com/fst/tz/multimedia_teleconferencing.mp4"></video>}
            <div className="home_page_logo"></div>
            <div className="closed_beta"><span>CLOSED BETA</span></div>
            {false && <div className="sign_in"><a href={this.props.link}>Create a TeleZing</a></div>}
            <Menu isOpen={this.state.burgerMenuOpen} width={220} right onStateChange={ this.isBurgerMenuOpen } bodyClassName={ "bm-menu-is-open" } >
                <a id="login" className="bm-menu-item" href={this.props.link}>Sign in / Create a TeleZing<br /><span className="small">Approved Beta Members Only</span></a>
               
            </Menu>
        </div>
        )
    }

}