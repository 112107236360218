import React from 'react';

import './App.css';
import HomePage from './HomePage';

function App() {


  let link = window.redirectUrl + "meeting#create";
  return (
    <div className="App">
     <HomePage link={link}/>
    </div>
  );
}

export default App;
